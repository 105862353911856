<template>
  <div>
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      updatedOn=""
      :showRegenerate="false"
      :showRegenerateButton="true"
    />

    <HeaderTabs :journalsTotal="formatNumber(transactionStore.journalList?.total)" />

    <TransactionScreening
      ref="transactionScreeningRef"
      tableType="transaction"
      :exportParams="exportParams"
      @onChangeSort="onChangeSort"
      @onFilterData="onFilterData"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.transfer" />
  </div>

  <div class="elv-transactions-transfer-table-list">
    <ListTable
      ref="listTableRef"
      :transactions-params="transactionsParams"
      :tableData="transactionsData"
      :tableLoading="tableLoading"
      @onChangePage="onChangePage"
      @onExport="onExportTransactionList"
      @onResetList="getTransactionsTableData"
      @onChangePageSize="onChangePageSize"
    />
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { formatNumber } from '@/lib/utils'
import timezone from 'dayjs/plugin/timezone'
import Header from '../../components/Header.vue'
import ListTable from './components/ListTable.vue'
import { map, cloneDeep, isEmpty } from 'lodash-es'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { exportProgressStatusType } from '@/config/global'
import { transactionFilterSaveType } from '@/config/transactions'
import TransactionScreening from '../components/ListScreening.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()

const listTableRef = useTemplateRef('listTableRef')
const transactionsHeaderRef = useTemplateRef<InstanceType<typeof Header>>('transactionsHeaderRef')
const transactionScreeningRef = useTemplateRef('transactionScreeningRef')
const loading = ref(false)
const tableLoading = ref(true)

const transactionsParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const transactionsData: any = ref({
  total: {
    amountInFC: 0,
    amountOutFC: 0
  },
  totalCount: 0,
  list: []
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const exportParams = computed(() => {
  const params: any = {
    type: exportProgressStatusType.transfer.value,
    extra: {
      sortField: 'datetime',
      sortDirection: transactionsParams.value.sortDirection
    }
  }

  const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === transactionFilterSaveType.TRANSFER
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params.extra = {
      ...params.extra,
      ...entityFilter.data
    }
    delete params.extra?.clearTime
    if (entityFilter.data?.currency?.length) {
      params.extra.currency = entityFilter.data?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
    if (entityFilter.data?.filterProtocolOptions?.length) {
      params.extra.defiHoldingProtocolIds = entityFilter.data?.filterProtocolOptions.map((item: any) => item.value)
      delete params.extra.filterProtocolOptions
    }
  }
  return params
})

const transactionsFilterParamsFormat = (): Record<string, unknown> => {
  let params: Record<string, unknown> = {}
  const currentFilterData = transactionStore.entityFilterList.find((item: any) => {
    return item.type === transactionFilterSaveType.TRANSFER
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter: any = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params = {
      ...params,
      ...entityFilter.data
    }
    if (entityFilter.data?.currency?.length) {
      params.currency = entityFilter.data?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
    delete params?.clearTime
    if (entityFilter.data?.journalTypeIds?.length) {
      params.journalTypeIds = entityFilter.data?.journalTypeIds.filter((item: string) => {
        return (
          item === '0' ||
          (!!transactionStore.journalTypeList?.length &&
            transactionStore.journalTypeList.find((journalType: any) => {
              return journalType.journalTypeId === item
            })) ||
          (!transactionStore.journalTypeList?.length && item)
        )
      })
      const filterData: any = cloneDeep(entityFilter.data)
      filterData.journalTypeIds = params.journalTypeIds
      transactionStore.editEntityFilter(entityId.value, filterData, transactionFilterSaveType.TRANSFER)
    }
    if (entityFilter.data?.filterProtocolOptions?.length) {
      params.defiHoldingProtocolIds = entityFilter.data?.filterProtocolOptions.map((item: any) => item.value)
      delete params.filterProtocolOptions
    }
  } else if (!entityFilter?.data?.clearTime) {
    const defaultDatetime: any = {
      operator: 'BETWEEN',
      value: [
        dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'year').startOf('day').format('YYYY-MM-DD'),
        dayjs().tz(entityStore.entityDetail?.timezone).endOf('day').format('YYYY-MM-DD')
      ]
    }
    transactionStore.editEntityFilter(entityId.value, { datetime: defaultDatetime }, transactionFilterSaveType.TRANSFER)
    params.datetime = defaultDatetime
  }
  return params
}

const getTransactionsTableData = async () => {
  try {
    tableLoading.value = true
    const filterParams = transactionsFilterParamsFormat()
    const params: Record<string, unknown> = {
      ...transactionsParams.value,
      ...filterParams
    }
    const response = await transactionStore.fetchTransactionList(entityId.value, params)
    listTableRef.value?.ref?.setScrollTop(0)
    transactionsData.value = response
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onExportTransactionList = async (exportList: any[]) => {
  const params = {
    type: 'TRANSACTION',
    extra: {
      transactionIds: map(exportList, 'transactionId')
    }
  }
  transactionScreeningRef.value?.exportButtonRef?.onExport(params)
}

/**
 * @description: 更改每页显示数量
 * @param {number} limit 每页显示数量
 */
const onChangePageSize = (limit: number) => {
  transactionsParams.value.limit = limit
  transactionsParams.value.page = 1
  getTransactionsTableData()
}

const onChangePage = (page: number) => {
  transactionsParams.value.page = page
  getTransactionsTableData()
}

const onChangeSort = (sort: string) => {
  transactionsParams.value.sortDirection = sort
  transactionsParams.value.page = 1
  getTransactionsTableData()
}
const onFilterData = async () => {
  transactionsParams.value.page = 1
  const filterParams = transactionsFilterParamsFormat()
  transactionStore.fetchTransactionStatistic(entityId.value, filterParams)
  getTransactionsTableData()
}

const onClearTransferListData = () => {
  transactionStore.journalGroupList = {
    total: 0,
    list: [],
    datetime: ''
  }
  transactionStore.transactionList = {
    list: []
  }
  transactionStore.transactionStatistics = {
    total: {
      amountInFC: '0',
      amountOutFC: '0'
    },
    filteredCount: 0
  }
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-transactions-list') {
        loading.value = true
        onClearTransferListData()
        transactionStore.transactionFilterDataInit(entityId.value, 'Transfer')
        const filterParams = transactionsFilterParamsFormat()
        transactionStore.fetchTransactionStatistic(entityId.value, filterParams)
        await getTransactionsTableData()
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  onClearTransferListData()
})
</script>
<style lang="scss" scoped>
.elv-transactions-transfer-table-list {
  flex: 1;
  min-height: 0px;
  position: relative;
}
</style>
